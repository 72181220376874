<template>
    <b-row class="justify-content-center">
        <b-col md="6">
            <b-card>
                <b-form v-on:submit.prevent>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group
                            label="Name"
                            label-for="bank_name"
                            >
                            <b-form-input
                                id="bank_name"
                                placeholder="Name"
                                v-model="form.name"
                                required
                            />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                            label="Account Name"
                            label-for="account_name"
                            >
                            <b-form-input
                                id="account_name"
                                placeholder="Account Name"
                                v-model="form.account_name"
                                required
                            />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                            label="Account No"
                            label-for="account_no"
                            >
                            <b-form-input
                                id="account_no"
                                placeholder="Account No"
                                v-model="form.account_no"
                                type="number"
                                required
                            />
                            </b-form-group>
                        </b-col>

                        <!-- submit and reset -->
                        <b-col md="12" class="mt-2">
                            <b-button
                            v-if="permissions.includes('master-payment-type-update')"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="success"
                            class="mr-1"
                            @click="updatePaymentType()"
                            >
                            Update
                            </b-button>
                            <b-button
                            v-if="permissions.includes('master-payment-type-delete')"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="danger"
                            class="mr-1"
                            @click="deletePaymentType()"
                            >
                            Delete
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {
    BFormSelect, BListGroupItem, BAvatar, BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        ToastificationContent,
        BFormSelect,
        BListGroupItem,
        BAvatar,
        BCard,
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        Prism
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            form: {
                name: '',
                account_name: '',
                account_no: null,
            },
            permissions: [],
            errors: '',
            errMessage: ''
        }
    },
    created() {
        this.getAllPermissions()
        this.getDetails()
    },
    methods: {
        getAllPermissions() {
            this.permissions = sessionStorage.getItem('permissions')
        },
        getDetails() {
            const id = this.$route.params.id
            this.$http.get('payment-type/'+id)
            .then(response => { 
                // console.log(response.data.data)
                this.form.name = response.data.data.name
                this.form.account_name = response.data.data.account_name
                this.form.account_no = response.data.data.account_no
            })
        },
        updatePaymentType() {
            const id = this.$route.params.id
            this.$http
            .post("payment-type/"+id+"/update", {
                "name": this.form.name,
                "account_name": this.form.account_name,
                "account_no": this.form.account_no
            })
            .then((response) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Success update Payment Type',
                        variant: 'success',
                    },
                })
                location.href = "/master/payment-type"
                // console.log(response.data.data)
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        deletePaymentType() {
            const id = this.$route.params.id
            this.$http
            .post("payment-type/"+id+"/delete")
            .then((response) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Payment Type has been deleted',
                        variant: 'danger',
                    },
                })
                location.href = "/master/payment-type"
                // console.log(response.data.data)
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                console.log(errors.response)
            })
        },
    },
}
</script>